<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Online Exam </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  <router-link to="/category">Exam </router-link> \
                  Online exams
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn 
                    @click="createOrUpdateExam()" 
                    class="btn btn-primary font-weight-bolder font-size-sm"
                    style="color: #fff;"
                  >
                  <i class="fa fa-plus"></i>
                    Add Exam
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field  label="Online Examination Title" v-model="search.info" outlined dense clearable>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-autocomplete
                      :items="grades" item-text="title" item-value="id"
                      label="Search by grades" clearable v-model="search.grade_name"
                      deletable-chips dense outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                <v-autocomplete
                    :items="categories" item-text="name" item-value="id"
                    label="Search by category" clearable v-model="search.category_id"
                    deletable-chips dense outlined>
                </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                <v-autocomplete
                    :items="sub_categories" item-text="name" item-value="id"
                    label="Search by sub category" clearable v-model="search.sub_category_id"
                    deletable-chips dense outlined>
                </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select v-model="search.candidate_exam_type"
                          :items="candidate_exam_types"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                          label="Performance Type">
                </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select v-model="search.exam_option"
                  :items="exam_options"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  label="Exam Option"
                  required
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                  <v-select :items="status" v-model="search.status" label="Status" item-text="name" item-value="value" outlined dense ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn @click.prevent="searchOnlineExamination()" class="btn btn-primary"  style="color: #fff">
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn @click.prevent="resetSearch()" class="btn ml-2 btn-secondary" >
                    <v-icon small  elevation="2" outlined>fas fa-power-off</v-icon>&nbsp;
                    Reset
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <table class="table"
                     id="kt_advance_table_widget_4">
                <thead>
                <tr class="text-left">
                  <th>Title</th>
                  <th>Subject Code</th>
                  <th>Grade</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Default Price</th>
                  <th>Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in examinations">
                    <td>
                      <a @click="createOrUpdateExam(item.id)" class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg">
                        {{item.title}}
                      </a>
                      <div>
                        <span>
                          <i class="fa fa-music" style="color: #95f542" v-if="item.require_playlist"></i>&nbsp;
                           <i class="fa fa-users" style="color: #f59b42" v-if="item.taken_as_group=='yes'"></i>
                        </span>
                        <div>
                           <span class="badge badge-info  font-weight-bolder label-inline" v-if="item.exam_option!='na'">
                            {{item.exam_option_text}}
                          </span>&nbsp;
                          <span class="label label-info  font-weight-bolder label-inline" v-if="item.candidate_exam_type!='na' && item.candidate_exam_type!=null">
                            {{item.candidate_exam_type_text}}
                          </span>&nbsp;
                          <span class="label label-info  font-weight-bolder label-inline" v-if="item.ask_for_instrument=='yes'">
                           Instrument required
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg">
                        {{ item.subject_code }}
                      </a>
                    </td>
                    <td>
                      <a class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg">
                        {{item.grade_text}}
                      </a>
                    </td>
                    <td>
                      <a class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg">
                        {{item.category_name }}
                      </a>
                    </td>
                    <td>
                      <a class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg">
                        {{item.sub_category_name }}
                      </a>
                    </td>
                    <td>
                      <a class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg">
                      {{item.final_price}}
                      </a>
                    </td>
                    <td>
                       <span class="badge"
                             v-bind:class="{ 'badge-success': item.status_text=='Active', 'badge-danger': item.status_text=='Inactive' }"
                       >{{ item.status_text }}</span>
                    </td>
                    <td class="pr-0">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.candidate_exam_type=='collaborative' && item.ask_for_instrument=='yes'">
                              <a @click="" class="navi-link">
                                <span class="navi-icon">
                                     <i class="fa fa-link" aria-hidden="true" style="color: #5A2119"></i>
                                </span>
                                <span class="navi-text">Linked Instrument</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="createOrUpdateExam(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.has_state_price==1" >
                              <a class="navi-link" @click="examPrice(item.id)">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-money-bill</v-icon>
                                </span>
                                <span class="navi-text">Price</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteExamination(item.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="examinations.length > 0"
                  class="pull-right mt-7"
                  @input="getAllOnlineExaminations"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-app>
</template>
<script>
import CategoryService from "@/services/product/category/CategoryService";
import SubCategoryService from "@/services/product/subCategory/SubCategoryService";
import GradeServce from "@/services/product/grade/GradeService";
import vMultiselectListbox from 'vue-multiselect-listbox';
import OnlineExaminationService from "@/services/product/examination/OnlineExaminationService";
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';

const category = new CategoryService();
const grade = new GradeServce();
const examination=new OnlineExaminationService();
const subCategory = new SubCategoryService();

export default {
  components: {
    vMultiselectListbox
  },
  data() {
    return{
      total: null,
      perPage: null,
      page: null,
      dialog:false,
      status: [
        {name: 'Status', value: null},
        {name: 'Active', value: 'active'},
        {name: 'Inactive', value: 'in_active'},
      ],
      categories:[],
      sub_categories:[],
      grades:[],
      examinations:[],
      candidate_exam_types: [],
      exam_options: [],
      search:{
        info:'',
        status:'',
        category_id:'',
        sub_category_id:'',
        grade_name:'',
        name:'',
        candidate_exam_type:'',
        exam_option:'',
      }
    }
  },
  methods: {
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    resetSearch(){
      this.search= {};
      this.getAllOnlineExaminations();
    },
    createOrUpdateExam(examinationId=null){
      if(examinationId==null)
        this.$router.push({
        name: "online-examination-create",
        });
      else
        this.$router.push({
          name:"online-examination-edit",
          params: {examinationId: examinationId}
        });
    },
    getAllOnlineExaminations(){
      examination
      .paginate(this.search, this.page)
          .then(response => {
            this.examinations=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          });
    },
    searchOnlineExamination(){
      this.getAllOnlineExaminations();
    },
    getAllCategories(){
      category
          .get()
          .then(response => {
            this.categories=response.data;
          })
          .catch((err) => {

          });
    },
    getAllSubCategories(){
      subCategory
          .get()
          .then(response => {
            this.sub_categories=response.data;
          })
          .catch((err) => {

          });
    },
    getAllGrades(){
      grade
          .getUniqueGradeTitle()
          .then(response => {
            this.grades=response.data.data;

          })
          .catch((err) => {

          });
    },
    deleteExamination(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            examination
                .delete(id)
                .then((response) => {
                  this.getAllOnlineExaminations();
                  this.$snotify.success("Online Examination Deleted Sucessfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });}
        },
      });
    },
    examPrice(examinationId){
      if(examinationId!=null)
        this.$router.push({
          name:"online-examination-exam-price",
          params: {examinationId: examinationId}
        });
    },
    getExamData(){
      examination
          .getExaminationData()
          .then(response => {
            for(let key in response.data.exam_options){
              this.exam_options.push({value:key, text:response.data.exam_options[key]})
            }
            for(let key in response.data.candidateExamTypes){
              this.candidate_exam_types.push({value:key,text:response.data.candidateExamTypes[key] })
            }
          });
    },
  },
  mounted() {
    this.getAllOnlineExaminations();
    this.getAllCategories();
    this.getAllSubCategories();
    this.getAllGrades();
    this.getExamData();
  }
}
</script>
